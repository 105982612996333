import * as React from "react";

const Slider = () => {
  const [focus, setFocus] = React.useState("about");
  const scroll_pos = React.useRef(null);

  React.useEffect(() => {
    scroll_pos.current.addEventListener("scroll", (event) => {
      const pos = scroll_pos.current.scrollTop;
      if (pos > 202 && pos < 450) {
        setFocus("aim");
      } else if (pos > 450) {
        setFocus("do");
      } else {
        setFocus("about");
      }
    });
  }, []);

  return (
    <>
      <div
        className={
          "flex flex-row box-border w-100 px-11 py-8 lg:py-20 flex-wrap justify-center"
        }
      >
        <div className={"flex flex-col justify-evenly p-11 text-center "}>
          <h1
            style={{ color: focus === "about" ? "black" : "#D3D3D3" }}
            className={`pb-5 md:pb-10 font-semibold text-xl md:text-3xl font-lexend-deca`}
          >
            About COMM-STEM
          </h1>
          <h1
            style={{ color: focus === "aim" ? "black" : "#D3D3D3" }}
            className={`pb-5 md:pb-10 font-semibold text-xl md:text-3xl font-lexend-deca`}
          >
            Our aim
          </h1>
          <h1
            style={{ color: focus === "do" ? "black" : "#D3D3D3" }}
            className={`font-semibold text-xl md:text-3xl font-lexend-deca`}
          >
            What we do?
          </h1>
        </div>
        <div
          className={
            "flex-col h-60 lg:h-80 overflow-y-scroll flipped px-10 pt-10 w-800"
          }
          ref={scroll_pos}
          id="scrollable"
        >
          <div className={"mb-32"}>
            <p className={`text-xl md:text-3xl font-lexend-dec font-normal`}>
              The University of Sydney COMM-STEM Society is USYD's premier
              non-profit student society for those studying combined Commerce
              and STEM degrees
            </p>
          </div>
          <div className={"mb-32"}>
            <p className={`text-xl md:text-3xl font-lexend-dec font-normal`}>
              Our aim is to connect our members with potential employers and
              industry professionals from a myriad of business and STEM fields,
              helping them broaden their perspectives and fast-track their
              professional career
            </p>
          </div>
          <div className={"mb-32"}>
            <p className={`text-xl md:text-3xl font-lexend-dec font-normal`}>
              From panel events and networking opportunities to fun socials, we
              provide students with industry insight, multi-disciplinary skills,
              and career advice to prepare them for success in the rapidly
              changing, lucrative world of business, science, and engineering{" "}
            </p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .flipped {
          direction: rtl;
          text-align: left;
        }
        #scrollable::-webkit-scrollbar {
          width: 20px;
        }
        #scrollable::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #f0f2f9;
        }
        #scrollable::-webkit-scrollbar-thumb {
          background: #0084ff;
          border-radius: 10px;
        }
        #scrollable::-webkit-scrollbar-thumb:hover {
          background: #00468b;
        }
      `}</style>
    </>
  );
};

export default Slider;
